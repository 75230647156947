





























import Form from '@/components/organisms/Purchase/Contract/Create/Form.vue'
import menuStore from '@/store/Menu'
import organizationGroupStore from '@/store/OrganizationGroups'
import organizationStore from '@/store/Organizations'
import planStore from '@/store/Plans'
import contractStore from '@/store/Contracts'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Form,
  },
})
export default class extends Vue {
  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })
    await Promise.all([
      organizationStore.fetchCurrentOrganization(),
      contractStore.fetchLatestContractForNoContract(),
      contractStore.fetchFutureContractForNoContract(),
      organizationGroupStore.fetchCount(),
      planStore.fetchPlans(),
    ])

    if (this.futureExists) {
      this.$router.replace({ name: 'Contract' })
    }
  }

  get futureExists(): boolean {
    return !(JSON.stringify(contractStore.futureContractForNoContract) === '{}')
  }
}
