
















































































import Spinner from '@/components/atoms/Spinner.vue'
import config from '@/config'
import contractStore, { Contract } from '@/store/Contracts'
import organizationGroupStore from '@/store/OrganizationGroups'
import organizationStore, { Organization } from '@/store/Organizations'
import planStore, { Plan } from '@/store/Plans'
import { Sbp, TAX_RATE } from '@/store/Purchase'
import dayjs from '@/libs/dayjs'
import { v4 as uuidv4 } from 'uuid'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { Spinner },
})
export default class extends Vue {
  form: HTMLFormElement | null = null
  selectedPlan: Plan | null = null
  selectedPayMethod = 'credit'
  hashSource = ''
  isLoading = false
  error = ''

  sbp = {
    merchantId: config.purchase.sbp.merchantId,
    serviceId: config.purchase.sbp.serviceId,
    payType: '1', //継続課金（簡易）
    autoChargeType: '1', //自動課金する
    serviceType: '0', //売上（購入）
    divSettele: '0', //前払い
    terminalType: '0', //PC
    successUrl: config.purchase.sbp.create.successUrl,
    cancelUrl: config.purchase.sbp.create.cancelUrl,
    errorUrl: config.purchase.sbp.create.errorUrl,
    pageconUrl: config.purchase.sbp.create.pageconUrl,
  }

  mounted(): void {
    this.form = document.forms.namedItem('purchaseForm')
  }

  get plans(): Plan[] {
    return planStore.plans.filter((plan) => plan.price > 0)
  }

  get currentOrganization(): Organization | null {
    return organizationStore.currentOrganization
  }

  get latestContract(): Contract | null {
    return contractStore.latestContractForNoContract
  }

  get isLatestTrial(): boolean {
    return this.latestContract?.plan?.price === 0
  }

  get orgGroupCount(): number {
    return organizationGroupStore.count
  }

  get getSelectedPayMethod(): string {
    return this.selectedPayMethod
  }

  get getSelectedPlan(): Plan | null {
    return this.selectedPlan
  }

  get maxGroups(): number | null {
    const maxGroups = this.selectedPlan?.maxGroups
    if (maxGroups === undefined) {
      return 0
    }
    return maxGroups
  }

  get canPurchase(): boolean {
    return this.selectedPlan ? true : false
  }

  back(): void {
    this.$router.push({
      name: 'Contract',
    })
  }

  async goToPayment(): Promise<void> {
    this.isLoading = true

    if (!this.isValidMaxGroups()) {
      this.error = '上限グループ数が現在のグループ数未満です。'
      this.isLoading = false
      return
    }

    try {
      if (this.form === null) {
        throw 'formが設定されていません。'
      }

      if (this.setRequestParams() === null) {
        throw '購入要求パラメータの設定に失敗しました。'
      }
      if (this.setHashSource() === null) {
        throw 'ハッシュ元文字列の作成に失敗しました。'
      }

      this.form.sps_hashcode.value = await this.generateHash()
      this.form.action = config.purchase.sbp.requestUrl
      this.form.submit()
    } catch (e) {
      this.error = '購入処理に失敗しました。'
      console.error(e)
    } finally {
      this.isLoading = false
    }
  }

  setRequestParams(): void | null {
    if (this.form === null) {
      return null
    }

    const campType = this.getCampType()
    if (campType === null) {
      return null
    }
    this.form.camp_type.value = campType

    const tax = this.culculateTax()
    if (tax === null) {
      return null
    }
    this.form.tax.value = tax

    const amount = this.culculateAmount()
    if (amount === null) {
      return null
    }
    this.form.amount.value = amount

    this.form.pay_method.value = this.getSelectedPayMethod
    this.form.item_id.value = this.getSelectedPlan?.itemId
    this.form.item_name.value = this.getSelectedPlan?.name
    this.form.cust_code.value = this.currentOrganization?.id
    this.form.order_id.value = this.generateContractId()
    this.form.request_date.value = dayjs().format('YYYYMMDDHHmmss')
  }

  setHashSource(): void | null {
    if (this.form === null) {
      return null
    }

    // NOTE: 「C002_リンク型システム仕様書（購入要求）」の「3.1. 要求項目定義」に記載されている順番に連結しないとダメ
    let hashSource = this.form.pay_method.value
    hashSource += this.form.merchant_id.value
    hashSource += this.form.service_id.value
    hashSource += this.form.cust_code.value
    hashSource += this.form.order_id.value
    hashSource += this.form.item_id.value
    hashSource += this.form.item_name.value
    hashSource += this.form.tax.value
    hashSource += this.form.amount.value
    hashSource += this.form.pay_type.value
    hashSource += this.form.auto_charge_type.value
    hashSource += this.form.service_type.value
    hashSource += this.form.div_settele.value
    hashSource += this.form.camp_type.value
    hashSource += this.form.terminal_type.value
    hashSource += this.form.success_url.value
    hashSource += this.form.cancel_url.value
    hashSource += this.form.error_url.value
    hashSource += this.form.pagecon_url.value
    hashSource += this.form.request_date.value
    hashSource += config.purchase.sbp.hashKey

    this.hashSource = hashSource
  }

  async generateHash(): Promise<string> {
    const encoder = new TextEncoder()
    const encodedSource = encoder.encode(this.hashSource)
    const hashBuffer = await crypto.subtle.digest('SHA-1', encodedSource)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  }

  formatPrice(price: number): string {
    return price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
  }

  culculatePriceIncludingTax(price: number): number {
    return price + price * TAX_RATE
  }

  culculateAmount(): number | null {
    const price = this.selectedPlan?.price
    if (price === undefined) {
      return null
    }
    const tax = this.culculateTax()
    if (tax === null) {
      return null
    }
    return price + tax
  }

  culculateTax(): number | null {
    const price = this.selectedPlan?.price
    if (price === undefined) {
      return null
    }
    return price * TAX_RATE
  }

  getCampType(): number | null {
    if (this.latestContract === null) {
      return null
    }

    if (JSON.stringify(this.latestContract) === '{}') {
      return Sbp.CampType.WITH_PAYMENT_THIS_MONTH
    } else if (dayjs(this.latestContract.endDate).isSame(dayjs().endOf('month'), 'days')) {
      return Sbp.CampType.WITHOUT_PAYMENT_THIS_MONTH
    } else if (this.isLatestTrial && dayjs(this.latestContract.endDate).isAfter(dayjs(), 'months')) {
      return Sbp.CampType.WITHOUT_PAYMENT_THIS_MONTH
    }
    return Sbp.CampType.WITH_PAYMENT_THIS_MONTH
  }

  generateContractId(): string {
    return uuidv4()
  }

  isValidMaxGroups(): boolean {
    if (this.maxGroups === null) {
      // NOTE: 今のところこの画面で上限グループ数がnullのケースは扱わないので無条件でアウトとする
      return false
    }
    if (this.orgGroupCount > this.maxGroups) {
      return false
    }
    return true
  }
}
